<template>
  <Section class="bg-white px-5 md:px-36 lg:px-64 flex justify-start items-center" v-smartnav>
    <div>
      <h3 class="text-6xl font-bold text-green-700">
        Every Human
        <span class="block text-gray-800">Has Rights</span>
      </h3>
      <br />
      <p class="text-gray-700">
        Learn more about your rights by directly contacting us using the form
        below.
      </p>
    </div>
  </Section>

  <div class="px-5 md:px-36 lg:px-64 py-10 w-full md:w-3/4">
    <h3 class="text-3xl font-semibold text-green-700">Human Rights Clinic</h3>

    <p class="mt-2 text-justify text-gray-700">
      I am a person living with HIV/AIDS. What are the rights that are most
      important to me? Ghana signed the Universal Declaration of Human Rights,
      which means that it is the responsibility of the Ghana government to
      protect all of the rights in that Declaration.
    </p>

    <br />
    <div class="bg-gray-50 p-5">
      <h4 class="text-2xl text-gray-800">1. Right to Privacy</h4>
      <p class="text-gray-800 text-justify mt-2">
        One of the most important rights for a person living with HIV/AIDS is
        the right to PRIVACY Privacy is the ability to keep certain parts of
        your person to yourself, and to not reveal them to others. The RIGHT TO
        PRIVACY is outlined in Article 12 of the UN Declaration, and is
        particularly important due to the stigma and discrimination that people
        living with HIV/AIDS face. Invading someone's privacy can happen in lots
        of ways.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        This includes reading someone's letters or correspondence, watching
        someone in their home, or revealing someone's HIV status without their
        permission. The invasion of an individual's privacy or defamation of
        character is a violation of human rights. For an individual living with
        HIV/AIDS, often, their HIV status is revealed to medical professionals.
        Medical professionals must uphold the privacy of their patients. They
        are NOT ALLOWED to reveal your HIV status, unless you pose a risk to
        someone else.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        This is because when they become doctors, they agree to a code of
        confidentiality, which restricts what they can tell other people about
        their patients. The Constitution of the Republic of Malawi guarantees
        that an individual will not be discriminated against on the basis of
        gender, race, color, ethnicity, religion, creed, social, or economic
        status. It is important to know that as an individual living with
        HIV/AIDS you still are entitled to equal protection under the law.
      </p>

      <br />
      <h4 class="text-2xl text-gray-800">2. Right to Fair Medical Treatment</h4>
      <p class="text-gray-800 text-justify mt-2">
        HIV/AIDS treatment by medical officers and any health research should be
        conducted in a non-discriminatory manner. Healthcare and Social Workers
        are NOT ALLOWEDto share private information (HIV test results) with
        anyone other than the patient, UNLESS they are given permission from the
        patient to do so, or if the patient fails to share information with
        sexual partners / individuals with whom they have shared a needle with.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        HIV/AIDS patients including medical personnel should NOT be
        discriminated against when in the process of receiving or assessing
        treatment, care, or research. HIV/AIDS patients have the right to
        consent or decline to be a part of medical research and if the patient
        consents they have the right to withdraw from the research at any point
        in time.
      </p>
    </div>
  </div>

  <div class="px-10 md:px-36 lg:px-64 py-10 bg-white">
    <h4 class="text-xl">Reach Out</h4>
    <br />
    <form @submit.prevent="" class="md:w-2/5">
      <div class="md:flex justify-between">
        <input
          type="text"
          placeholder="Full Name"
          class="bg-gray-200 p-2 focus:outline-none md:mr-1 w-full"
          required
        />

        <input
          type="email"
          placeholder="Email"
          class="bg-gray-200 p-2 focus:outline-none mt-2 md:mt-0 md:ml-1 w-full"
          required
        />
      </div>

      <div class="mt-4 md:mt-2">
        <textarea
          placeholder="What would you like to know or share?"
          class="bg-gray-200 p-2 focus:outline-none w-full"
          required
        />
      </div>

      <button type="submit" class="bg-green-700 hover:bg-green-600 transition-colors text-white px-5 py-1 mt-2 w-full md:w-auto">Send</button>
    </form>
  </div>
</template>
<script>
import Section from "@/components/Section.vue";

export default {
  name: "Clinic",
  components: {
    Section,
  },
};
</script>
<style scoped>
</style>